import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "inline-block relative mr-4 text-12px xxl:text-14px" }
const _hoisted_2 = {
  key: 0,
  class: "w-4",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_3 = {
  key: 1,
  class: "w-4",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("input", {
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSearch && _ctx.onSearch(...args))),
      value: _ctx.value,
      placeholder: _ctx.placeholder,
      class: 
        `${
          _ctx.value ? 'text-black-lp-200' : 'text-gray-lp-500'
        } block appearance-none w-full bg-white border border-gray-lp-400 px-3 py-2.5 xxl:py-2.5 pr-12 rounded leading-tight focus:outline-none`
      
    }, null, 42, ["value", "placeholder"]),
    _createVNode("div", {
      class: "absolute inset-y-0 right-0 items-center px-2 py-2 mr-2",
      onClick: _cache[2] || (_cache[2] = () => (_ctx.value ? _ctx.onClear() : {}))
    }, [
      (!_ctx.value)
        ? (_openBlock(), _createBlock("svg", _hoisted_2, [
            _createVNode("path", {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M10 4C8.4087 4 6.88258 4.63214 5.75736 5.75736C4.63214 6.88258 4 8.4087 4 10C4 10.7879 4.15519 11.5681 4.45672 12.2961C4.75825 13.0241 5.20021 13.6855 5.75736 14.2426C6.31451 14.7998 6.97595 15.2417 7.7039 15.5433C8.43185 15.8448 9.21207 16 10 16C10.7879 16 11.5681 15.8448 12.2961 15.5433C13.0241 15.2417 13.6855 14.7998 14.2426 14.2426C14.7998 13.6855 15.2417 13.0241 15.5433 12.2961C15.8448 11.5681 16 10.7879 16 10C16 8.4087 15.3679 6.88258 14.2426 5.75736C13.1174 4.63214 11.5913 4 10 4ZM4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 11.0506 17.7931 12.0909 17.391 13.0615C17.1172 13.7226 16.7565 14.3425 16.3196 14.9054L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L14.9054 16.3196C14.3425 16.7565 13.7226 17.1172 13.0615 17.391C12.0909 17.7931 11.0506 18 10 18C8.94943 18 7.90914 17.7931 6.93853 17.391C5.96793 16.989 5.08601 16.3997 4.34315 15.6569C3.60028 14.914 3.011 14.0321 2.60896 13.0615C2.20693 12.0909 2 11.0506 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315Z",
              fill: _ctx.colorIcon
            }, null, 8, ["fill"])
          ]))
        : (_openBlock(), _createBlock("svg", _hoisted_3, [
            _createVNode("path", {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z",
              fill: _ctx.colorIcon
            }, null, 8, ["fill"])
          ]))
    ])
  ]))
}